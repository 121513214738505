<template>
  <div class="examination" v-loading="loading">
    <div class="content">
      <el-menu
        :default-active="activeIndex"
        class="el-menu-demo"
        mode="horizontal"
        @select="handleSelect"
      >
        <el-menu-item index="0">当前考试</el-menu-item>
        <el-menu-item index="1">历史考试</el-menu-item>
      </el-menu>
      <el-card
        class="card-box"
        v-for="(i, _i) in list"
        :key="_i"
        v-show="i.showStatus == activeIndex"
      >
        <!-- 
        v-show="i.showStatus == activeIndex" -->
        <div class="title">{{ i.title }}</div>
        <div class="date">
          考试时间：{{ i.startDate }} {{ i.startTime }}~{{ i.endDate }}
          {{ i.endTime }}
        </div>
        <div class="limit">考试时长：{{ i.times }}分钟</div>
        <examinationBotton @onClick="into" :item="i"></examinationBotton>
      </el-card>
      <div class="empty" v-if="!list.length && !loading">您暂无考试</div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import examinationBotton from "./examinationBotton";
export default {
  name: "examination",
  data() {
    return {
      activeIndex: 0,
      loading: true,
      totalList: [],
      list: [],
    };
  },
  methods: {
    handleSelect(key) {
      this.activeIndex = key;
      this.list = this.totalList.filter(
        (el) => el.showStatus == this.activeIndex
      );
    },
    initData() {
      this.$https.get("/api/my/exam/list").then((res) => {
        if (res.success) {
          const data = res.data;
          this.totalList = data.map((el) => {
            return {
              ...el,
              startDate: moment(el.startDate).format("YYYY-MM-DD"),
              endDate: moment(el.endDate).format("YYYY-MM-DD"),
              startsTime: el.startDate,
              endTsTime: el.endDate,
              statusType: changeStatus(el.status),
              showStatus:
                el.status == "unstart" || el.status == "processing" ? 0 : 1,
            };
          });
          this.list = this.totalList.filter((el) => el.showStatus == 0);
        }
        this.loading = false;
        function changeStatus(type) {
          let list = {
            unstart: "开始考试",
            processing: "继续考试",
            done: "已考完",
            graded: "已考完",
            terminated: "被退考",
          };
          return list[type];
        }
      });
    },
    into(item) {
      // if (item.status == "unstart") {
      //   this.$message.error("考试未开始");
      //   return;
      // }
      if (item.status != "done" && item.status != "graded" && !item.unread) {
        const startDate = item.startsTime;
        const endDate = item.endTsTime;
        const timestamp = new Date().getTime();
        if (timestamp >= startDate && timestamp <= endDate) {
          this.initData();
          let url = "/examinationDetails?";
          Object.keys(item).map((el) => {
            url += "&" + el + "=" + item[el];
          });
          window.open(url);
        } else {
          if (timestamp < startDate) {
            this.$message.error("考试未开始");
          } else if (timestamp > endDate) {
            this.$message.error("考试已结束");
          }
        }
        // this.$router.push({
        //   path: "/examinationDetails",
        //   query: {
        //     id: item.examinationId,
        //     status: item.needCam ? 0 : 1
        //   },
        // });
      } else {
        if (item.unread) {
          this.$message.error("请学习完课程");
        } else {
          this.$message.error("进入考试失败:" + item.status);
        }
      }
    },
  },
  components: {
    examinationBotton,
  },
  mounted() {
    this.initData();

    this.$storage.setStorage([
      {
        key: "examination",
        value: [],
      },
    ]);
  },
};
</script>
<style lang="less">
.examination {
  position: relative;
  text-align: center;
  padding: 2% 0;
  height: 100%;
  .content {
    display: inline-block;
    width: 90%;
    height: 100%;
    text-align: left;
    .card-box {
      width: 31.33%;
      margin: 1%;
      text-align: left;
      border-radius: 5px;
      display: inline-block;
      padding: 20px;
      cursor: pointer;
      line-height: 2;
      color: #677897;
      .title {
        font-weight: bold;
        font-size: 16px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .start {
        float: right;
        padding: 0 20px;
        line-height: 30px;
        margin-bottom: 5px;
      }
      .no-start {
        color: #fff;
        background: #bec1c5;
      }
    }
  }
}
</style>
